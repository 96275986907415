<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <!-- form -->
        <validation-observer
          v-if="!verified"
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="name@vergosauctions.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Συνθηματικό</label>
                <b-link
                  :to="{name:'auth-forgot-password'}"
                  href="/forgot-password"
                >
                  <small>Ξέχασα τον κωδικό μου</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Να με θυμάσαι
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Σύνδεση
            </b-button>
          </b-form>
        </validation-observer>
        <div v-else>
          <div v-if="firstTime">
            Please check your email to add the QR code
          </div>
          <validation-observer
            ref="login2FAForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login2FA"
            >

              <!-- one_time_password -->
              <b-form-group
                label-for="one_time_password"
                label="Please fill in the next field with the code from you generator."
              >
                <validation-provider
                  #default="{ errors }"
                  name="one_time_password"
                  rules="required"
                >
                  <b-form-input
                    id="one_time_password"
                    v-model="one_time_password"
                    name="login-one_time_password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="One time password"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Σύνδεση
              </b-button>
            </b-form>
          </validation-observer>
        </div>

        <b-card-text class="text-center mt-2">
          <span>© {{ new Date().getFullYear() }} Vergos Auctions by 33clouds</span>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text" />
        </div>
        <b-link
          href="https://coordi.com/el/oroi-chrisis/"
          target="_blank"
        >
          <span>Όροι χρήσης</span>
        </b-link>

        <b-link
          href="https://33clouds.com/#contact"
          target="_blank"
          style="float:right"
        >
          <span>Επικοινωνία</span>
        </b-link>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      one_time_password: '',
      token: '',
      firstTime: false,
      verified: false,
      userData: {},

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              this.userData = response.data.userData
              this.token = response.data.accessToken
              this.firstTime = response.data.firstTime
              this.verified = true
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Προσοχή',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
              this.$refs.loginForm.setErrors(error.response.data.message)
            })
        }
      })
    },
    login2FA() {
      useJwt.login2FA({
        one_time_password: this.one_time_password,
      }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          // console.log('response.data', response.data)
          useJwt.setToken(this.token)
          useJwt.setRefreshToken(this.token)
          localStorage.setItem('userData', JSON.stringify(this.userData))
          this.$ability.update(this.userData.ability)
          this.$router.replace(getHomeRouteForLoggedInUser(this.userData.role))
        })
        .catch(error => {
          // console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Προσοχή',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
          this.$refs.loginForm.setErrors(error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
