var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),(!_vm.verified)?_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"login-email","state":errors.length > 0 ? false:null,"placeholder":"name@vergosauctions.com","autofocus":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Συνθηματικό")]),_c('b-link',{attrs:{"to":{name:'auth-forgot-password'},"href":"/forgot-password"}},[_c('small',[_vm._v("Ξέχασα τον κωδικό μου")])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"login-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Να με θυμάσαι ")])],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[_vm._v(" Σύνδεση ")])],1)]}}],null,false,1890829176)}):_c('div',[(_vm.firstTime)?_c('div',[_vm._v(" Please check your email to add the QR code ")]):_vm._e(),_c('validation-observer',{ref:"login2FAForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login2FA($event)}}},[_c('b-form-group',{attrs:{"label-for":"one_time_password","label":"Please fill in the next field with the code from you generator."}},[_c('validation-provider',{attrs:{"name":"one_time_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"one_time_password","name":"login-one_time_password","state":errors.length > 0 ? false:null,"placeholder":"One time password","autofocus":""},model:{value:(_vm.one_time_password),callback:function ($$v) {_vm.one_time_password=$$v},expression:"one_time_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[_vm._v(" Σύνδεση ")])],1)]}}])})],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("© "+_vm._s(new Date().getFullYear())+" Vergos Auctions by 33clouds")])]),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"})]),_c('b-link',{attrs:{"href":"https://coordi.com/el/oroi-chrisis/","target":"_blank"}},[_c('span',[_vm._v("Όροι χρήσης")])]),_c('b-link',{staticStyle:{"float":"right"},attrs:{"href":"https://33clouds.com/#contact","target":"_blank"}},[_c('span',[_vm._v("Επικοινωνία")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }